import request from '@/utils/request'

export function  treeArea(query) {
  return request({
    url: '/sys/area/tree',
    method: 'get',
    params: query
  })
}


export function  refreshArea(query) {
  return request({
    url: '/sys/area/refresh',
    method: 'get',
    params: query
  })
}

// 查询省市区地区信息列表
export function listArea(query) {
  return request({
    url: '/sys/area/list',
    method: 'get',
    params: query
  })
}

// 查询省市区地区信息分页
export function pageArea(query) {
  return request({
    url: '/sys/area/page',
    method: 'get',
    params: query
  })
}

// 查询省市区地区信息详细
export function getArea(data) {
  return request({
    url: '/sys/area/detail',
    method: 'get',
    params: data
  })
}

// 新增省市区地区信息
export function addArea(data) {
  return request({
    url: '/sys/area',
    method: 'post',
    data: data
  })
}

// 修改省市区地区信息
export function updateArea(data) {
  return request({
    url: '/sys/area',
    method: 'put',
    data: data
  })
}

// 删除省市区地区信息
export function delArea(data) {
  return request({
    url: '/sys/area/' +data,
    method: 'delete'
  })
}
